import React, { useState } from "react"
import { games, countries, usStates } from "../Games"
import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import Table from "@material-ui/core/Table"

const OddsComparision = () => {
  const [country, setCountry] = useState("USA")
  const [state, setState] = useState("Any")
  const changeState = state => {
    setState(state)
  }
  const changeCountry = country => {
    setCountry(country)
    if (country !== "USA") {
      setState("Any")
    }

  }

  const odds = game => game[1].odds.jackpot * game[1].odds.extra

  const filterForState = game => state === "Any" || game[1].state === state
  const filterForCountry = game => country === "Any" || game[1].country === country
  const filterGames = game => filterForCountry(game) && filterForState(game)
  return (
    <Paper>
      <Box p={2}>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField select label="Country" onChange={e => changeCountry(e.target.value)} value={country ?? ""}>
              {countries.map(gameCountry => <MenuItem value={gameCountry} key={gameCountry}>{gameCountry}</MenuItem>)}
            </TextField>
          </Grid>
          {country === "USA" && (<Grid item xs={12} sm={4}>
              <TextField select label="State" onChange={e => changeState(e.target.value)} value={state ?? ""}>
                <MenuItem value="Any" key="Any">Any</MenuItem>
                {usStates.map(usState => <MenuItem value={usState} key={usState}>{usState}</MenuItem>)}
              </TextField>
            </Grid>
          )}
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell>Game</TableCell>
              <TableCell>Odds of Winning the Jackpot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(games).filter(filterGames).sort((game1, game2) => odds(game1) - odds(game2)).map((game, rank) =>
              <TableRow key={game[0]}>
                <TableCell>{rank + 1}.</TableCell>
                <TableCell>{game[0]}</TableCell>
                <TableCell>1 in {new Intl.NumberFormat().format(odds(game))}</TableCell>
              </TableRow>,
            )}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  )
}

OddsComparision.propTypes = {}

export default OddsComparision
