import React from "react"
import { graphql, Link } from "gatsby"
import OddsComparision from "../components/tools/OddsComparision"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FAQItem from "../components/FAQItem"

const LotteryOddsComparision = ({ data, location }) =>
  <Layout location={location} title={data.site.siteMetadata.title}>
    <SEO title="Which Lottery Has the Best Odds?"
         description="Which lottery is the easiest to win? We have a database that contains the odds for all the lottery games around the world." />
    <h1>Which Lottery Game Has the Best Odds?</h1>
    <p>
      Want to find out which lottery game is the easiest to win?
    </p>
    <strong>
      With <b>Lottolibrary's Lottery Odds Comparision Tool</b> you can compare the odds of all the popular lottery games from around the world.
    </strong>
    <br/>
    <br/>
    <OddsComparision />
    <h2>FAQ</h2>
    <FAQItem question="How Much Does It Cost to Use the Lottery Odds Comparision Tool? Is It Free?">
      <strong>Our lottery odds comparision tool is free to
        use.</strong> There are no hidden fees or charges.<br />
      <i>Please note: We might show you 3<sup>rd</sup> party ads or affiliate links. You can read more about this in
        our <Link to="/terms-and-conditions/">T&amp;C document</Link>.</i>
    </FAQItem>
    <FAQItem question="Which Lottery Game Has the Better Odds Megamillions or Powerball?">
      <strong>The Powerball jackpot is easier to win than Megamillions.</strong> You have a chance of 1 in 292,201,338 for hitting the Powerball jackpot, while the odds of winning the Megamillions jackpot is 1 in 302,575,350.
    </FAQItem>
    <h2>Disclaimer</h2>
    <section>
      This tool is intended for informational, educational, and entertainment purposes only.
    </section>
    <h2>Similar Tools</h2>
    <section>
      <ul>
        <li>
          <Link to="/lottery-number-generator/">Lottery Number Generator</Link>
        </li>
        <li>
          <Link to="/lottery-odds-calculator/">Lottery Odds Calculator</Link>
        </li>
        <li>
          <Link to="/lottery-tax-calculator/">Lottery Tax Calculator</Link>
        </li>
        <li>
          <Link to="/best-odds/">Lottery Odds Comparison</Link>
        </li>
        <li>
          <Link to="/expected-value-calculator/">Expected Value Calculator</Link>
        </li>
      </ul>
    </section>
    <h2>Related Articles</h2>
    <section>
      <ul>
        <li>
          <Link to="/can-you-predict-the-lottery/">Can You Predict The Lottery?</Link>
        </li>
        <li>
          <Link to="/are-lotteries-rigged/">Are Lotteries Rigged?</Link>
        </li>
      </ul>
    </section>
    <h2>References</h2>
    <section>
      <ul>
        <li>
          <a
            href="https://en.wikipedia.org/wiki/Geometric_progression" target="_blank" rel="noreferrer">geometric
            progression</a>
        </li>
        <li>
          <a
            href="https://powerball.com" target="_blank" rel="noreferrer">Powerball</a>
        </li>
        <li>
          <a
            href="https://www.megamillions.com/FAQs.aspx" target="_blank" rel="noreferrer">Mega Millions FAQ</a>
        </li>
      </ul>
    </section>
  </Layout>

export default LotteryOddsComparision

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
}
`
